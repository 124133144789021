<template>
  <div id="settleReview">
    <div class="search-form ant-round-form">
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_pay_076') }}: </span>
        <a-input
          v-model:value="searchQuery.batchNo"
          allow-clear
          @pressEnter="loadReviewList(1)"
          @blur="loadReviewList(1)"
        />
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_pay_077') }}: </span>
        <a-range-picker
          v-model:value="searchQuery.dateOpts"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          @change="loadReviewList(1)"
        />
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_pay_078') }}: </span>
        <a-select
          v-model:value="searchQuery.payType"
          allow-clear
          @change="loadReviewList(1)"
        >
          <a-select-option
            v-for="opt in payTypeOpts"
            :key="opt.code"
            :value="opt.code"
          >{{ opt.name }}</a-select-option>
        </a-select>
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_pay_071') }}: </span>
        <a-select
          v-model:value="searchQuery.remitCurrency"
          show-search
          allow-clear
          option-filter-prop="label"
          @change="loadReviewList(1)"
        >
          <a-select-option
            v-for="item in payCurList"
            :key="item.currency"
            :value="item.currency"
            :label="`${item.currency} - ${item.currencyName}`"
          >
            <img :src="getCountryLogo(item.currency)" class="select-img-country">
            <span>{{ item.currency }} - {{ item.currencyName }}</span>
          </a-select-option>
        </a-select>
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_pay_072') }}: </span>
        <a-select
          v-model:value="searchQuery.arrivalCurrency"
          show-search
          allow-clear
          option-filter-prop="label"
          @change="loadReviewList(1)"
        >
          <a-select-option
            v-for="item in receiveCurList"
            :key="item.currencyCode"
            :value="item.currencyCode"
            :label="`${item.currencyCode} - ${item.currencyName}`"
          >
            <img :src="getCountryLogo(item.imgName || item.currencyCode)" class="select-img-country">
            <span>{{ item.currencyCode }} - {{ item.currencyName }}</span>
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="table-part">
      <a-table
        :scroll="{ x: 1500 }"
        :loading="loading"
        :pagination="false"
        :custom-header-row="() => { return {class: 'my-table-tr'}}"
        :columns="columns"
        :data-source="reviewList"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'edit'">
            <a-space style="width: 100%; justify-content: space-between">
              <a-button
                v-show="getUsedCheck(pageLabs, 150103)"
                danger
                type="link"
                size="small"
                @click="handleReview(record)"
              >{{ $t('pages_pay_016') }}</a-button>
              <a-divider v-show="getUsedCheck(pageLabs, 150103)" type="vertical" />
              <a-button
                size="small"
                type="link"
                @click="handleView(record)"
              >{{ $t('pages_pay_083') }}</a-button>
            </a-space>
          </template>
        </template>
      </a-table>
      <table-page
        :total="total"
        :page-opts="searchQuery"
        @update-page="updatePage"
      />
    </div>
    <pay-review
      v-model:visible="checkVisible"
      :watch-attr="watchAttr"
      :apply-no="applyCheckNo"
      @refresh="loadReviewList"
      @refuse="loadReviewList"
    />
    <view-details
      v-model:visible="viewVisible"
      :apply-no="applyViewNo"
    />
  </div>
</template>
<script>
import PayReview from './components/pay-review'
import ViewDetails from './components/view-details'
import TablePage from '@/components/table-page'
import { reactive, toRefs, computed } from 'vue'
import { _account, _pay } from '@/api'
import i18n from '@/locale'
import { getUsedCheck, getAttrStatusList, parseMoney, getCountryLogo, getTradeStatusName } from '@/util'
export default {
  name: 'SettleReview',
  components: {
    'pay-review': PayReview,
    'view-details': ViewDetails,
    'table-page': TablePage
  },
  props: {
    payCurList: {
      type: Array,
      default: () => { return [] }
    },
    receiveCurList: {
      type: Array,
      default: () => { return [] }
    }
  },
  setup () {
    const state = reactive({
      searchQuery: {
        pageNum: 1,
        pageSize: 10,
        batchNo: null,
        payType: null,
        remitCurrency: null,
        arrivalCurrency: null,
        dataOpts: []
      },
      loading: true,
      checkVisible: false,
      viewVisible: false,
      applyViewNo: '',
      applyCheckNo: '',
      watchAttr: 0,
      total: 0,
      pageLabs: [],
      reviewList: [],
      columns: [
        { key: 'payType', dataIndex: 'payTypeText', title: i18n.global.t('pages_pay_078'), width: 120 },
        { key: 'batchNo', dataIndex: 'batchNo', title: i18n.global.t('pages_pay_076'), width: 200 },
        { key: 'applyCnt', dataIndex: 'applyCnt', title: i18n.global.t('pages_pay_080'), width: 180 },
        { key: 'createTime', dataIndex: 'createTime', title: i18n.global.t('pages_pay_077'), width: 200 },
        { key: 'remitAmount', dataIndex: 'remitAmountText', title: i18n.global.t('pages_pay_032'), width: 200 },
        { key: 'arrivalAmount', dataIndex: 'arrivalAmountText', title: i18n.global.t('pages_pay_081'), width: localStorage.getItem('locale-language') === 'en' ? 220 : 200 },
        { key: 'feeAmt', dataIndex: 'feeAmtText', title: i18n.global.t('pages_pay_369'), width: 200 },
        { key: 'edit', title: i18n.global.t('common_text_001'), align: 'center', fixed: 'right', width: localStorage.getItem('locale-language') === 'en' ? 230 : 175 }
      ]
    })

    state.payTypeOpts = computed(() => {
      return getAttrStatusList('singleOrBatch')
    })

    const handleReview = (record) => {
      state.checkVisible = true
      state.applyCheckNo = record.batchNo
      state.watchAttr = (new Date()).getTime()
    }

    const handleView = (record) => {
      state.viewVisible = true
      state.applyViewNo = record.batchNo
    }

    const exportList = async () => {
      const { dateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        beginDate: dateOpts[0] ? `${dateOpts[0]} 00:00:00` : '',
        endDate: dateOpts[1] ? `${dateOpts[1]} 23:59:59` : ''
      })
      await _pay.orderPaymentExport(query)
    }

    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNum = page
      state.searchQuery.pageSize = pageSize
      loadReviewList()
    }

    const loadChildrenMenu = async () => {
      const res = await _account.getChildrenMenu({ parentId: 1501, auth: 'Y' })
      if (res.data.succ) state.pageLabs = res.data.data || []
    }
    const loadReviewList = async (pageNum) => {
      state.loading = true
      if (!state.searchQuery.dateOpts) state.searchQuery.dateOpts = []
      if (pageNum) state.searchQuery.pageNum = pageNum
      const { dateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        beginDate: dateOpts[0] ? `${dateOpts[0]} 00:00:00` : '',
        endDate: dateOpts[1] ? `${dateOpts[1]} 23:59:59` : ''
      })
      const res = await _pay.queryOrderPayment(query)
      state.loading = false
      if (res.data.succ) {
        state.reviewList = res.data.data.list.map(item => {
          return Object.assign({}, item, {
            payTypeText: getTradeStatusName(item.payType, 'singleOrBatch'),
            applyStatuText: getTradeStatusName(item.applyStatus, 'payStatus'),
            remitAmountText: item.remitAmount ? `${item.remitCurrency} ${parseMoney(item.remitAmount)}` : '-',
            arrivalAmountText: `${item.arrivalCurrency} ${parseMoney(item.arrivalAmount)}`,
            feeAmtText: `${item.feeCurrency} ${parseMoney(item.feeAmt)}`
          })
        })
        state.total = res.data.data.total
      }
    }

    const init = () => {
      loadReviewList()
      loadChildrenMenu()
    }

    return {
      getUsedCheck,
      getCountryLogo,
      init,
      exportList,
      updatePage,
      handleView,
      handleReview,
      loadReviewList,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#settleReview {
  .search-form {
    margin-top: 8px;
  }
  .table-part {
    margin-top: 12px;
  }
}
</style>

