<template>
  <div id="payReviewer">
    <header-nav :content="`${$t('pages_router_007')} / ${ $t('pages_router_027')}`" />
    <div class="common-card-body">
      <a-tabs
        v-model:activeKey="activeKey"
        class="header-tabs"
      >
        <template #rightExtra>
          <a-button
            type="primary"
            ghost
            style="min-width: 100px"
            :loading="exportLoading"
            @click="handleExport"
          >
            <template #icon><download-outlined /></template>
            {{ exportLoading ? $t('common_text_023') : $t('common_text_033') }}
          </a-button>
        </template>
        <a-tab-pane key="outbound" :tab="$t('pages_pay_110')">
          <reviewer-outbounds ref="outboundRef" :pay-cur-list="payCurList" :receive-cur-list="receiveCurList" />
        </a-tab-pane>
        <a-tab-pane key="inbound" :tab="$t('pages_pay_111')">
          <reviewer-inbounds ref="inboundRef" :pay-cur-list="payCurList" :receive-cur-list="receiveCurList" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, watch, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import HeaderNav from '@/components/header-nav'
import ReviewerOutbounds from './reviewerOutbounds'
import ReviewerInbounds from './reviewerInbounds'
import { _pay, _receive } from '@/api'
export default {
  name: 'PayReviewer',
  components: {
    'header-nav': HeaderNav,
    'reviewer-outbounds': ReviewerOutbounds,
    'reviewer-inbounds': ReviewerInbounds
  },
  setup() {
    const route = useRoute()
    const state = reactive({
      activeKey: route.query.key || 'outbound',
      exportLoading: false,
      payCurList: [],
      receiveCurList: []
    })

    const outboundRef = ref(null)
    const inboundRef = ref(null)

    watch(() => state.activeKey,
      value => {
        nextTick(() => {
          if (value === 'inbound' && inboundRef.value) inboundRef.value.init()
          if (value === 'outbound' && outboundRef.value) outboundRef.value.init()
        })
      },
      { immediate: true }
    )

    const handleExport = async () => {
      state.exportLoading = true
      if (state.activeKey === 'outbound') await outboundRef.value.exportList()
      else await inboundRef.value.exportList()
      state.exportLoading = false
    }

    const loadPayCurList = async () => {
      const res = await _pay.getCurAccount()
      if (res.data.succ) {
        state.payCurList = res.data.data
      }
    }

    const loadReceiveCurList = async () => {
      const res = await _receive.getAllCurrency()
      if (res.data.succ) {
        state.receiveCurList = res.data.data
      }
    }

    loadPayCurList()
    loadReceiveCurList()

    return {
      outboundRef,
      inboundRef,
      handleExport,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#payReviewer {
  .common-card-body {
    padding: 8px 0;
    .header-tabs:deep(.ant-tabs-nav) {
      padding: 0 18px;
    }
  }
}
</style>

