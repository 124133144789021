<template>
  <div id="foreignOutbounds">
    <div v-if="kycFlag" class="kyc-alert">
      <a-alert v-if="kycStatus === 'CUSTOMER'" banner>
        <template #message>
          <span>{{ $t('pages_pay_161') }}</span>
          <a>{{ $t('pages_pay_162') }}</a>
        </template>
      </a-alert>
      <a-alert v-else-if="kycStatus === 'CUSTREJ'" banner>
        <template #message>
          <span>{{ $t('pages_pay_160') }}</span>
          <a>{{ $t('pages_pay_162') }}</a>
        </template>
      </a-alert>
      <a-alert v-else :message="$t('pages_pay_159')" banner />
    </div>
    <div v-else>
      <div class="search-form ant-round-form">
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_076') }}: </span>
          <a-input
            v-model:value="searchQuery.batchNo"
            allow-clear
            @pressEnter="loadOutbounds(1)"
            @blur="loadOutbounds(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_077') }}: </span>
          <a-range-picker
            v-model:value="searchQuery.dateOpts"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            @change="loadOutbounds(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_078') }}: </span>
          <a-select
            v-model:value="searchQuery.paymentType"
            allow-clear
            @change="loadOutbounds(1)"
          >
            <a-select-option
              v-for="opt in payTypeOpts"
              :key="opt.code"
              :value="opt.code"
            >{{ opt.name }}</a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_071') }}: </span>
          <a-select
            v-model:value="searchQuery.debitCurrency"
            show-search
            allow-clear
            option-filter-prop="label"
            @change="loadOutbounds(1)"
          >
            <a-select-option
              v-for="item in payCurList"
              :key="item.currency"
              :value="item.currency"
              :label="`${item.currency} - ${item.currencyName}`"
            >
              <img :src="getCountryLogo(item.currency)" class="select-img-country">
              <span>{{ item.currency }} - {{ item.currencyName }}</span>
            </a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_pay_072') }}: </span>
          <a-select
            v-model:value="searchQuery.paymentCurrency"
            show-search
            allow-clear
            option-filter-prop="label"
            @change="loadOutbounds(1)"
          >
            <a-select-option
              v-for="item in receiveCurList"
              :key="item.currencyCode"
              :value="item.currencyCode"
              :label="`${item.currencyCode} - ${item.currencyName}`"
            >
              <img :src="getCountryLogo(item.imgName || item.currencyCode)" class="select-img-country">
              <span>{{ item.currencyCode }} - {{ item.currencyName }}</span>
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="table-part">
        <a-table
          :scroll="{ x: 1500 }"
          :loading="loading"
          :pagination="false"
          :custom-header-row="() => { return {class: 'my-table-tr'}}"
          :columns="columns"
          :data-source="outbounds"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'edit'">
              <a-space style="width: 100%; justify-content: space-between">
                <a-button
                  v-show="getUsedCheck(pageLabs, 150202)"
                  size="small"
                  type="link"
                  danger
                  @click="handleReview(record)"
                >{{ $t('pages_pay_016') }}</a-button>
                <a-divider v-show="getUsedCheck(pageLabs, 150202)" type="vertical" />
                <a-button
                  size="small"
                  type="link"
                  @click="handleView(record)"
                >{{ $t('pages_pay_083') }}</a-button>
              </a-space>
            </template>
          </template>
        </a-table>
        <table-page
          :total="total"
          :page-opts="searchQuery"
          @update-page="updatePage"
        />
      </div>
    </div>
    <a-modal
      :visible="repeatVisible"
      :mask-closable="false"
      :ok-button-props="{ disabled: countNum !== 0 }"
      :ok-text="countNum === 0 ? $t('pages_pay_068') : countNum + $t('pages_pay_186')"
      @ok="handleOk"
      @cancel="() => repeatVisible = false"
    >
      <template #title>
        <div class="flex-align">
          <exclamation-circle-outlined style="color: #faad14; font-size: 24px; margin-right: 12px;" />
          <span>{{ $t('pages_pay_179') }}</span>
        </div>
      </template>
      <div v-if="repeatList.length < 10" class="tip-body" style="margin-top: -12px">
        <span>{{ $t('pages_pay_180') }}</span>
        <span>{{ repeatDesc }}</span>
      </div>
      <div v-else class="tip-body" style="margin-top: -12px">
        <div>{{ $t('pages_pay_180') }}{{ repeatSize }}{{ $t('pages_pay_181') }}{{ hisCt }}{{ $t('pages_pay_182') }}</div>
        <div style="margin-top: 12px">
          {{ $t('pages_pay_265') }}
          <a-button type="link" size="small" @click="downFiles">{{ $t('pages_pay_266') }}</a-button>
          {{ $t('pages_pay_267') }}
        </div>
      </div>
      <div style="margin-top: 12px">
        {{ $t('pages_pay_183') }}
        <span style="color: #ff4d4f">{{ $t('pages_pay_184') }}</span>
        {{ $t('pages_pay_185') }}
      </div>
    </a-modal>
    <edit-modal
      ref="editRef"
      v-model:visible="visible"
      @refresh="handleRefresh"
    />
  </div>
</template>
<script>
import { ref, reactive, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import TablePage from '@/components/table-page'
import EditModal from './components/edit-modal'
import { _pay, _account, _oss } from '@/api'
import { getUsedCheck, getAttrStatusList, getTradeStatusName, getCountryLogo, parseMoney } from '@/util'
import i18n from '@/locale'
import moment from 'moment'
export default {
  name: 'ForeignOutbounds',
  components: {
    'table-page': TablePage,
    'edit-modal': EditModal
  },
  props: {
    payCurList: {
      type: Array,
      default: () => { return [] }
    },
    receiveCurList: {
      type: Array,
      default: () => { return [] }
    }
  },
  setup () {
    let timer = ''
    const editRef = ref(null)

    const store = useStore()
    const state = reactive({
      loading: false,
      visible: false,
      repeatVisible: false,
      applyVisible: false,
      needCheck: false,
      kycFlag: false,
      kycStatus: '',
      pageLabs: [],
      outbounds: [],
      total: 0,
      countNum: 5,
      newPayItem: {},
      repeatList: [],
      repeatSize: 0,
      repeatDesc: '',
      repeatFilePath: '',
      hisCt: 0,
      searchQuery: {
        pageNum: 1,
        pageSize: 10,
        batchNo: '',
        paymentType: '',
        dateOpts: [],
        debitCurrency: '',
        paymentCurrency: ''
      },
      columns: [
        { key: 'paymentType', dataIndex: 'paymentTypeText', title: i18n.global.t('pages_pay_078'), width: 120 },
        { key: 'batchApplyNo', dataIndex: 'batchApplyNo', title: i18n.global.t('pages_pay_076'), width: 200 },
        { key: 'applyCnt', dataIndex: 'applyCnt', title: i18n.global.t('pages_pay_080'), width: 180 },
        { key: 'uploadFilename', dataIndex: 'uploadFilename', title: i18n.global.t('pages_pay_262'), width: 300 },
        { key: 'createDate', dataIndex: 'createAt', title: i18n.global.t('pages_pay_077'), width: 200 },
        { key: 'remitAmount', dataIndex: 'remitAmountText', title: i18n.global.t('pages_pay_032'), width: 200 },
        { key: 'arrivalAmount', dataIndex: 'arrivalAmountText', title: i18n.global.t('pages_pay_081'), width: localStorage.getItem('locale-language') === 'en' ? 220 : 200 },
        { key: 'feeAmt', dataIndex: 'feeAmtText', title: i18n.global.t('pages_pay_369'), width: 200 },
        { key: 'edit', title: i18n.global.t('common_text_001'), align: 'center', fixed: 'right', width: localStorage.getItem('locale-language') === 'en' ? 230 : 175 }
      ]
    })

    state.payTypeOpts = computed(() => {
      return getAttrStatusList('outboundPayModel')
    })

    const downFiles = async () => {
      const res = await _oss.querySsoTempUrl({ filePath: state.repeatFilePath })
      window.open(res.data.data)
    }

    const exportList = async () => {
      const { dateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        beginDate: dateOpts[0] ? `${dateOpts[0]} 00:00:00` : '',
        endDate: dateOpts[1] ? `${dateOpts[1]} 23:59:59` : ''
      })
      await _pay.outboundsReviewExport(query)
    }

    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNum = page
      state.searchQuery.pageSize = pageSize
      loadOutbounds()
    }

    const handleRefresh = () => {
      state.repeatVisible = false
      loadOutbounds()
    }

    const handleView = (record) => {
      state.visible = true
      editRef.value.loadPage(record, 'view')
    }

    const handleReview = async (record) => {
      const res = await _pay.checkRecentRepeatOrder({
        batchApplyNo: record.batchApplyNo,
        business: 'OUTBOUND'
      })
      if (res.data.succ && res.data.data) {
        clearInterval(timer)
        const { list, repeatSize, hisCt, repeatFilePath } = res.data.data

        state.countNum = 5
        state.newPayItem = record
        state.repeatList = list
        state.repeatSize = repeatSize
        state.repeatDesc = list.map(item => item.errorMsg).join(',')
        state.hisCt = hisCt
        state.repeatFilePath = repeatFilePath
        state.repeatVisible = true

        timer = setInterval(() => {
          state.countNum--
          if (state.countNum <= 0) clearInterval(timer)
        }, 1000)
      } else {
        state.visible = true
        editRef.value.loadPage(record, 'review')
      }
    }

    const handleOk = () => {
      state.repeatVisible = false
      state.visible = true
      editRef.value.loadPage(state.newPayItem, 'review')
    }

    const loadPage = () => {
      const { status } = store.getters.getUserInfo

      if (status === 'OPENFINSH') {
        loadOutbounds()
      } else {
        state.kycStatus = status
        state.kycFlag = true
      }
    }

    const loadChildrenMenu = async () => {
      let pageLabs = []
      const res1 = await _account.getChildrenMenu({ parentId: 1502, auth: 'Y' })
      const res2 = await _account.getChildrenMenu({ parentId: 1702 })
      if (res1.data.succ) pageLabs = res1.data.data || []
      if (res2.data.succ) pageLabs = pageLabs.concat(res2.data.data || [])
      state.pageLabs = pageLabs
    }

    const loadMerchantProInfos = async () => {
      const userInfo = store.getters.getUserInfo
      const res = await _pay.getMerchantProInfos({
        merchantNo: userInfo.merchantNo,
        proId: 4
      })
      if (res.data.succ) {
        res.data.data.map(item => {
          if (item.isChecked === '01') {
            if (item.checkStatus === '02' || item.checkStatus === '01') {
              state.needCheck = false
            } else {
              state.needCheck = true
            }
          }
        })
      }
    }

    const loadOutbounds = async (pageNum) => {
      state.loading = true
      if (!state.searchQuery.dateOpts) state.searchQuery.dateOpts = []
      if (pageNum) state.searchQuery.pageNum = pageNum
      const { dateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        beginDate: dateOpts[0] ? `${dateOpts[0]} 00:00:00` : '',
        endDate: dateOpts[1] ? `${dateOpts[1]} 23:59:59` : ''
      })
      const res = await _pay.getForeignPayConfirmList(query)
      if (res.data.succ) {
        state.outbounds = res.data.data.pageInfo.list.map(item => {
          return Object.assign({}, item, {
            paymentTypeText: getTradeStatusName(item.paymentType, 'outboundPayModel'),
            remitAmountText: item.debitAmt ? `${item.debitCurrency} ${parseMoney(item.debitAmt)}` : '-',
            arrivalAmountText: item.paymentAmt ? `${item.paymentCurrency} ${parseMoney(item.paymentAmt)}` : '-',
            feeAmtText: item.feeAmt ? `${item.feeCurrency} ${parseMoney(item.feeAmt)}` : '-',
            statusText: getTradeStatusName(item.applyStatus, 'payStatus'),
            createAt: moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')
          })
        })
        state.total = res.data.data.pageInfo.total
      }
      state.loading = false
    }

    const init = () => {
      loadChildrenMenu()
      loadMerchantProInfos()
      loadPage()
    }

    return {
      editRef,
      getUsedCheck,
      getCountryLogo,
      init,
      downFiles,
      handleOk,
      updatePage,
      handleRefresh,
      handleView,
      handleReview,
      loadOutbounds,
      exportList,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
#foreignOutbounds {
  text-align: left;
  .kyc-alert {
    margin-top: 24px;
  }
  .search-form {
    margin-top: 8px;
  }
  .table-part {
    margin-top: 12px;
  }
}
</style>

